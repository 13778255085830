import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Alert from "react-bootstrap/Alert";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import InputGroup from "react-bootstrap/InputGroup";
import YourRoomsTab from "./tabs/YourRoomsTab"
import SharedRoomsTab from "./tabs/SharedRoomsTab";
import Paginator from "../../components/Paginator";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBook, faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { faBarsStaggered } from "@fortawesome/free-solid-svg-icons";
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';


import useRoomAPI from "../../services/useRoomAPI";
import useSharedRoomAPI from "../../services/useSharedRoomAPI";
import useSingleRoomAPI from "../../services/useSingleRoomAPI";
import useOrganizations from "../../services/useOrganizations";

const RoomsContent = ({
    auth,
    restricted,
    restrictedStudentProjects,
    refreshRooms,
    rooms,
    newRoom,
    showInviteModal,
    showAssignModal,
    handleSearch,
    searchValue,
    sortValue,
    setSortValue,
    page,
    setPage,
    refreshSingleRoom,
    isLoadingRooms,
    roomsError,
}) => {
    const [tempSearch, setTempSearch] = useState("");

    const [shared_rooms, isLoadingShared, sharedError, refreshShared] =
        useSharedRoomAPI(page, searchValue, sortValue, auth);


    const refreshAllRooms = () => {
        refreshRooms();
        refreshShared();
    }


    const handleSort = (newSort) => {
        setPage(1);
        setSortValue(newSort);
    };



    return (
        <Container className="bg-light pe-2 ps-2 pe-md-4 ps-md-4">
            {restricted && (
                <Row className="mt-4">
                    <Col className="d-flex justify-content-start">
                        <Alert variant="warning">
                            <Link to="/buy">Upgrade to Premium</Link> to create
                            unlimited rooms.
                        </Alert>
                    </Col>
                </Row>
            )}
            <Row className="mt-3 mb-3">
                <Col className="">
                    <InputGroup>
                        <Form.Control
                            type="text"
                            placeholder={"Search your Lessons"}
                            aria-label="Filter rooms by name"
                            value={tempSearch}
                            onChange={(e) => setTempSearch(e.target.value)}
                            onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    handleSearch(tempSearch);
                                }
                            }}
                        />
                        <Button
                            variant="light border"
                            onClick={() => handleSearch(tempSearch)}
                        >
                            <FontAwesomeIcon icon={faSearch} fixedWidth />
                        </Button>
                        <DropdownButton
                            variant="light border"
                            as={ButtonGroup}
                            title={
                                <>
                                    <FontAwesomeIcon
                                        icon={faBarsStaggered}
                                        fixedWidth
                                    />
                                    <span className="ms-3 d-none d-md-inline">
                                        Sort
                                    </span>
                                </>
                            }
                            id="bg-vertical-dropdown-1"
                        >
                            <Dropdown.Item
                                onClick={() => handleSort("title")}
                                eventKey="1"
                            >
                                {sortValue === "title" ? (
                                    <span>
                                        A-Z
                                        <i className="bi bi-check float-end text-success"></i>
                                    </span>
                                ) : (
                                    <span>A-Z</span>
                                )}
                            </Dropdown.Item>
                            <Dropdown.Item
                                onClick={() => handleSort("-title")}
                                eventKey="1"
                            >
                                {sortValue === "-title" ? (
                                    <span>
                                        Z-A
                                        <i className="bi bi-check float-end text-success"></i>
                                    </span>
                                ) : (
                                    <span>Z-A</span>
                                )}
                            </Dropdown.Item>
                            <Dropdown.Item
                                onClick={() => handleSort("-id")}
                                eventKey="1"
                            >
                                {sortValue === "-id" ? (
                                    <span>
                                        Newest
                                        <i className="bi bi-check float-end text-success"></i>
                                    </span>
                                ) : (
                                    <span>Newest</span>
                                )}
                            </Dropdown.Item>
                            <Dropdown.Item
                                onClick={() => handleSort("id")}
                                eventKey="1"
                            >
                                {sortValue === "id" ? (
                                    <span>
                                        Oldest
                                        <i className="bi bi-check float-end text-success"></i>
                                    </span>
                                ) : (
                                    <span>Oldest</span>
                                )}
                            </Dropdown.Item>
                        </DropdownButton>
                    </InputGroup>
                </Col>
            </Row>
            <Row className="mb-4 g=4">
                <Col className="">
                    <Tabs
                        defaultActiveKey="my lessons"
                        id="uncontrolled-tab-example"
                        className="mb-3"
                    >
                        <Tab eventKey="my lessons" title="My Lessons">
                            <YourRoomsTab 
                                auth={auth}
                                restricted={restricted}
                                restrictedStudentProjects={restrictedStudentProjects}
                                refreshRooms={refreshAllRooms}
                                rooms={rooms}
                                newRoom={newRoom}
                                showInviteModal={showInviteModal}
                                showAssignModal={showAssignModal}
                                handleSearch={handleSearch}
                                searchValue={searchValue}
                                sortValue={sortValue}
                                setSortValue={setSortValue}
                                page={page}
                                setPage={setPage}
                                refreshSingleRoom={refreshSingleRoom}
                                isLoadingRooms={isLoadingRooms}
                                roomsError={roomsError}
                            />
                        </Tab>

                        <Tab eventKey="shared with me" title="Shared With Me">
                            <SharedRoomsTab
                                auth={auth}
                                restricted={restricted}
                                restrictedStudentProjects={restrictedStudentProjects}
                                refreshRooms={refreshAllRooms}
                                newRoom={newRoom}
                                showInviteModal={showInviteModal}
                                showAssignModal={showAssignModal}
                                handleSearch={handleSearch}
                                shared_rooms={shared_rooms}
                                searchValue={searchValue}
                                sortValue={sortValue}
                                setSortValue={setSortValue}
                                refreshShared={refreshShared}
                                page={page}
                                setPage={setPage}
                                isLoadingShared={isLoadingShared}
                                sharedError={sharedError}
                                refreshSingleRoom={refreshSingleRoom}
                            />
                        </Tab>
                    </Tabs>
                </Col>
            </Row>
        </Container>
    );
    mt - 3;
};

export default RoomsContent;
