import React, { useState } from "react";

import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import ListGroup from "react-bootstrap/ListGroup";
import Row from "react-bootstrap/Row";
import Badge from "react-bootstrap/Badge";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";

import { getPicture } from "../../utils/get-picture";
import TemplateCard from "./cards/TemplateCard";
import BundleCard from "./cards/BundleCard";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

import PreviewModal from "./modals/PreviewModal";

import moment from "moment";
import "moment-timezone";

import CreateRoomModal from "./modals/CreateRoomModal";
import CreateStudentProjectModal from "./modals/CreateStudentProjectModal";
import { toast } from "react-hot-toast";
import { Helmet } from "react-helmet";

import { useAuth } from "../../context/AuthContext";

const getFiletypeIcon = (filename) => {
    return <i className="bi bi-filetype-pdf"></i>;
};

const ListingDetailContent = ({
    template,
    refreshTemplate,
    reviewing,
    profile,
}) => {
    const [showEditModal, setShowEditModal] = useState(false);
    const [showCreateRoomModal, setShowCreateRoomModal] = useState(false);
    const [remix, setRemix] = useState(false);
    const [showCreateStudentProjectModal, setShowCreateStudentProjectModal] =
        useState(false);
    const auth = useAuth();

    const signedIn = !!(!auth.isLoading && auth.profile);

    const copyToClipboard = () => {
        navigator.clipboard.writeText(window.location);
        toast.success("Link copied!");
    };

    const showPreviewHint = () => {
        toast(
            (t) => (
                <div className="d-flex flex-column">
                    <p className="mb-1">This is just a picture.</p>
                    <p className="fw-muted mb-1">
                        <small>
                            Click the "Preview" button below to look at the
                            Lesson.
                        </small>
                    </p>
                    <Button
                        size="sm"
                        variant="purple-dark"
                        onClick={() => toast.dismiss(t.id)}
                    >
                        Got it!
                    </Button>
                </div>
            ),
            { duration: 3500 }
        );
    };

    return (
        <>
            <Helmet>
                <title>{template.title} - MegaMinds</title>
                <meta property="og:title" content={template.title} />
                <meta property="og:type" content="megaminds:lesson" />
                <meta property="og:url" content={window.location} />
                <meta
                    property="og:image"
                    content={template.image ? template.image : null}
                />
            </Helmet>
            <CreateRoomModal
                show={showCreateRoomModal}
                setShowModal={setShowCreateRoomModal}
                onUpdate={refreshTemplate}
                template={template}
                remix={remix}
            />
            <CreateStudentProjectModal
                show={showCreateStudentProjectModal}
                setShowModal={setShowCreateStudentProjectModal}
                onUpdate={refreshTemplate}
                template={template}
            />
            {!reviewing && (
                <Container fluid className="g-0 bg-light">
                    <Row className="bg-white p-3 p-lg-4 border-bottom m-0">
                        <Col xs={12}>
                            <Link to="/explore">
                                <i className="bi bi-arrow-left me-2"></i>Explore
                            </Link>
                        </Col>
                    </Row>
                </Container>
            )}
            <Container className="g-0 bg-light">
                <Row className="bg-light m-0 mt-0">
                    {template.image && (
                        <img
                            alt="Promotional for this template"
                            className="m-0 p-0 img-fluid"
                            onClick={() => showPreviewHint()}
                            style={{ maxHeight: "350px", objectFit: "cover" }}
                            src={getPicture(template.image)}
                        />
                    )}
                </Row>
                <Row className="m-3">
                    <Col xs={12}>
                        <h2 className="mb-0">{template.title}</h2>
                    </Col>
                </Row>
                {!reviewing && (
                    <Row className="m-3 pb-3 border-bottom">
                        <Col>
                            {template.assignment_mode ? (
                                <Row className="g-2">
                                    <Col xs={12} md={"auto"}>
                                        <Button
                                            variant="success"
                                            className="text-start w-100"
                                            onClick={() =>
                                                setShowCreateStudentProjectModal(
                                                    true
                                                )
                                            }
                                        >
                                            <FontAwesomeIcon
                                                icon={faPlus}
                                                fixedWidth
                                            />
                                            <span className="ms-3">
                                                {signedIn
                                                    ? "New Student Project"
                                                    : "Get Started"}
                                            </span>
                                        </Button>
                                    </Col>
                                    <Col xs={12} md={"auto"}>
                                        <PreviewModal
                                            title={template.title}
                                            setShowCreateModal={() =>
                                                setShowCreateStudentProjectModal(
                                                    true
                                                )
                                            }
                                            link={
                                                process.env
                                                    .REACT_APP_3D_ENDPOINT +
                                                "/" +
                                                template.source_classroom_clone
                                                    .hub_id
                                            }
                                        />
                                    </Col>
                                    <Col xs={12} md={"auto"}>
                                        <Button
                                            variant="purple-dark"
                                            className="text-start w-100"
                                            onClick={() => copyToClipboard()}
                                        >
                                            <i className="bi bi-share-fill"></i>
                                            <span className="ms-3">Share</span>
                                        </Button>
                                    </Col>
                                    {signedIn && (
                                        <Col xs={12} md={"auto"}>
                                            <Button
                                                variant="purple-dark"
                                                className="text-start w-100"
                                                onClick={() => {
                                                    setRemix(true);
                                                    setShowCreateRoomModal(
                                                        true
                                                    );
                                                }}
                                            >
                                                <i className="bi bi-pencil-fill"></i>
                                                <span className="ms-3">
                                                    Remix
                                                </span>
                                            </Button>
                                        </Col>
                                    )}
                                </Row>
                            ) : (
                                <Row className="g-2">
                                    <Col xs={12} md={"auto"}>
                                        <Button
                                            variant="success"
                                            className="text-start w-100"
                                            onClick={() =>
                                                setShowCreateRoomModal(true)
                                            }
                                        >
                                            <FontAwesomeIcon
                                                icon={faPlus}
                                                fixedWidth
                                            />
                                            <span className="ms-3">
                                                {signedIn
                                                    ? "Add to My Lessons"
                                                    : "Get Started"}
                                            </span>
                                        </Button>
                                    </Col>
                                    <Col xs={12} md={"auto"}>
                                        <PreviewModal
                                            title={template.title}
                                            setShowCreateModal={() =>
                                                setShowCreateRoomModal(true)
                                            }
                                            link={
                                                process.env
                                                    .REACT_APP_3D_ENDPOINT +
                                                "/" +
                                                template.source_classroom_clone
                                                    .hub_id
                                            }
                                        />
                                    </Col>
                                    <Col xs={12} md={"auto"}>
                                        <Button
                                            variant="purple-dark"
                                            className="text-start w-100"
                                            onClick={() => copyToClipboard()}
                                        >
                                            <i className="bi bi-share-fill"></i>
                                            <span className="ms-3">Share</span>
                                        </Button>
                                    </Col>
                                </Row>
                            )}
                        </Col>
                    </Row>
                )}
                <Row className="m-3">
                    <Col
                        xs={12}
                        className="d-flex justify-content-start align-items-center mt-3"
                    >
                        <div className="d-flex flex-row flex-nowrap">
                            {template.categories.map((category) => {
                                if (category.hidden) return null;
                                return (
                                    <Link
                                        as="span"
                                        to="/explore/"
                                        state={{
                                            selectedCategories: [category],
                                        }}
                                        key={template.id + "-" + category.id}
                                        className="badge pill-badge me-1 bg-secondary"
                                    >
                                        {category.title}
                                    </Link>
                                );
                            })}
                        </div>
                    </Col>
                </Row>
                <Row className="bg-light m-3 mt-3">
                    <Col xs={12} lg={6}>
                        <h5 className="fw-bold">Description</h5>
                        <p style={{ whiteSpace: "pre-wrap" }}>
                            {template.description}
                        </p>
                    </Col>
                    <Col xs={12} lg={6} className="mb-3">
                        <Row>
                            <Col xs={12}>
                                <h5 className="fw-bold">Creator</h5>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} className="d-flex align-items-center">
                                <img
                                    alt=""
                                    className="img-fluid rounded-circle"
                                    style={{ maxHeight: "30px" }}
                                    variant="top"
                                    src={getPicture(template.creator.image)}
                                />
                                <span className="ms-2">
                                    {template.creator.public_name}
                                </span>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} lg={12} className="mt-3">
                                <h5 className="fw-bold">Details</h5>
                                <Row>
                                    <Col>
                                        <ListGroup>
                                            {template.grades && (
                                                <ListGroup.Item>
                                                    <Row>
                                                        <Col xs={1}>
                                                            <i className="bi bi-backpack"></i>
                                                        </Col>
                                                        <Col className="fw-light">
                                                            {template.grades.map(
                                                                (grade) => (
                                                                    <Badge
                                                                        bg="secondary"
                                                                        className="me-1"
                                                                        as={
                                                                            Link
                                                                        }
                                                                        state={{
                                                                            selectedGrades:
                                                                                [
                                                                                    grade,
                                                                                ],
                                                                        }}
                                                                        to="/explore/"
                                                                    >
                                                                        Grade{" "}
                                                                        {
                                                                            grade.title
                                                                        }
                                                                    </Badge>
                                                                )
                                                            )}
                                                        </Col>
                                                    </Row>
                                                </ListGroup.Item>
                                            )}
                                            {template.resource_types && (
                                                <ListGroup.Item>
                                                    <Row>
                                                        <Col xs={1}>
                                                            <i className="bi bi-book"></i>
                                                        </Col>
                                                        <Col className="fw-light">
                                                            {template.resource_types.map(
                                                                (
                                                                    resourceType
                                                                ) => (
                                                                    <Badge
                                                                        bg="secondary"
                                                                        className="me-1"
                                                                        as={
                                                                            Link
                                                                        }
                                                                        state={{
                                                                            selectedResourceTypes:
                                                                                [
                                                                                    resourceType,
                                                                                ],
                                                                        }}
                                                                        to="/explore/"
                                                                    >
                                                                        {
                                                                            resourceType.title
                                                                        }
                                                                    </Badge>
                                                                )
                                                            )}
                                                        </Col>
                                                    </Row>
                                                </ListGroup.Item>
                                            )}
                                            <ListGroup.Item>
                                                <Row>
                                                    <Col xs={1}>
                                                        <i class="bi bi-calendar-plus"></i>
                                                    </Col>
                                                    <Col className="fw-light">
                                                        Created{" "}
                                                        {moment(
                                                            template.timestamp_created
                                                        ).format("MMMM Do")}
                                                    </Col>
                                                </Row>
                                            </ListGroup.Item>
                                            {template.recommended_users && (
                                                <ListGroup.Item>
                                                    <Row>
                                                        <Col xs={1}>
                                                            <i class="bi bi-people-fill"></i>
                                                        </Col>
                                                        <Col className="fw-light">
                                                            {
                                                                template.recommended_users
                                                            }{" "}
                                                            users
                                                        </Col>
                                                    </Row>
                                                </ListGroup.Item>
                                            )}
                                        </ListGroup>
                                    </Col>
                                    <Col xs={12}></Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                    {template.templateattachement_set.length !== 0 && (
                        <Col xs={12} lg={6} className="mt-5">
                            <h5 className="fw-bold">Attachments</h5>
                            <Row>
                                <Col>
                                    <ListGroup>
                                        {template.templateattachement_set.map(
                                            (attachement) => (
                                                <ListGroup.Item>
                                                    <Row>
                                                        <Col xs={12}>
                                                            <span className="pe-3">
                                                                {getFiletypeIcon(
                                                                    attachement.filename
                                                                )}
                                                            </span>
                                                            <a
                                                                href={
                                                                    attachement.file
                                                                }
                                                                variant="link"
                                                                className="m-0 p-0"
                                                                download
                                                            >
                                                                {
                                                                    attachement.filename
                                                                }
                                                            </a>
                                                        </Col>
                                                    </Row>
                                                </ListGroup.Item>
                                            )
                                        )}
                                    </ListGroup>
                                </Col>
                                <Col xs={12}></Col>
                            </Row>
                        </Col>
                    )}
                </Row>
                {template.bundles.length !== 0 && (
                    <Row className="bg-light m-3 mt-5">
                        <Col xs={12}>
                            <h5 className="fw-bold">
                                {template.bundles.length > 1
                                    ? "Part of collections"
                                    : "Part of collection"}
                            </h5>
                        </Col>
                        {template.bundles.map((room) => (
                            <BundleCard bundle={room} />
                        ))}
                    </Row>
                )}
                {template.similars.length !== 0 && (
                    <Row className="bg-light m-3 mt-5">
                        <Col xs={12}>
                            <h5 className="fw-bold">You may also like</h5>
                        </Col>
                        {template.similars.map((room) => (
                            <TemplateCard template={room} />
                        ))}
                    </Row>
                )}
            </Container>
            <Container>
                {profile?.experimental_optin && (
                    <Button
                        href={
                            process.env.REACT_APP_3D_ENDPOINT +
                            "/" +
                            template.source_classroom_clone.hub_id +
                            "?editTemplate"
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                        variant="link"
                        className="text-start"
                    >
                        <i className="bi bi-pencil-fill"></i>
                        <span className="ms-3">Admin Edit Lesson</span>
                    </Button>
                )}
                <Row className="m-3 mt-5 d-md-none">
                    {!reviewing && (
                        <>
                            <Col xs={12} md={4}>
                                <Button
                                    variant="success"
                                    className="text-start w-100"
                                    onClick={() => setShowCreateModal(true)}
                                >
                                    <FontAwesomeIcon icon={faPlus} fixedWidth />
                                    <span className="ms-3">
                                        {signedIn
                                            ? "Add to My Lessons"
                                            : "Get Started"}
                                    </span>
                                </Button>
                            </Col>
                            <Col xs={12} md={4} className="">
                                <Button
                                    as={Link}
                                    to="/explore"
                                    variant="link"
                                    className="text-start w-100 p-0 pt-2"
                                >
                                    <span className="">Explore more Lessons</span>
                                </Button>
                            </Col>
                        </>
                    )}
                </Row>
            </Container>
        </>
    );
};

export default ListingDetailContent;
