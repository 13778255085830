import React, { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import useFeaturedAPI from "../services/useFeaturedAPI";
import Paginator from "../components/Paginator";
import useTemplateAPI from "../services/useTemplateAPI";
import { useLocation } from "react-router-dom";

import TemplateCard from "./explore/cards/TemplateCard";
import BundleCard from "./explore/cards/BundleCard";
import FilterSidebar from "./explore/FilterSidebar";
import MobileFilter from "./explore/MobileFilter";
import SortButtons from "./explore/SortButtons";
import ExploreHeader from "./explore/Header";

import { useAuth } from "../context/AuthContext";

import { Helmet } from "react-helmet";

const Explore = () => {
    const auth = useAuth();
    const { state } = useLocation();

    // Pagination
    const [page, setPage] = useState(1);

    // Searching
    const [tempSearchValue, setTempSearchValue] = useState("");
    const [searchValue, setSearchValue] = useState("");

    // Sorting
    const [sortValue, setSortValue] = useState("");

    // Facet Filtering
    const [selectedCategories, setSelectedCategories] = useState(
        state?.selectedCategories ? state.selectedCategories : []
    );

    const [selectedGrades, setSelectedGrades] = useState(
        state?.selectedGrades ? state.selectedGrades : []
    );

    const [selectedResourceTypes, setSelectedResourceTypes] = useState(
        state?.selectedResourceTypes ? state.selectedResourceTypes : []
    );

    const [selectedFormats, setSelectedFormats] = useState(
        state?.selectedFormats ? state.selectedFormats : []
    );

    const [featured, isFeaturedLoading, featuredError] = useFeaturedAPI();

    const [templates, isApiLoading, error, refresh] = useTemplateAPI(
        searchValue,
        selectedCategories,
        selectedGrades,
        selectedResourceTypes,
        selectedFormats,
        sortValue,
        page,
        auth
    );

    const resetFilters = () => {
        setSortValue("");
        setTempSearchValue("");
        setSearchValue("");
        setSelectedCategories([]);
        setSelectedGrades([]);
        setSelectedResourceTypes([]);
        setSelectedFormats([]);
    };

    useEffect(() => {
        // Reset to page 1 on state changes
        setPage(1);
    }, [
        searchValue,
        selectedGrades,
        selectedResourceTypes,
        selectedCategories,
        selectedFormats,
    ]);

    const showFeaturedMenu =
        page === 1 &&
        selectedCategories.length === 0 &&
        selectedGrades.length === 0 &&
        selectedResourceTypes.length === 0 &&
        selectedFormats.length === 0 &&
        searchValue === "" &&
        sortValue === "";

    return (
        <>
            <Helmet>
                <title>Explore | MegaMinds</title>
            </Helmet>
            <ExploreHeader profile={auth.profile} />
            <Row className="m-0 p-0 vh-100">
                {/* Section for Sidebar Facet Filter */}
                <Col
                    xs={12}
                    sm={4}
                    lg={4}
                    xl={3}
                    className="bg-white m-0 p-4 border-end"
                >
                    <MobileFilter
                        resetFilters={resetFilters}
                        showFeatured={showFeaturedMenu}
                        selectedCategories={selectedCategories}
                        setSelectedCategories={setSelectedCategories}
                        selectedResourceTypes={selectedResourceTypes}
                        setSelectedResourceTypes={setSelectedResourceTypes}
                        selectedGrades={selectedGrades}
                        setSelectedGrades={setSelectedGrades}
                        selectedFormats={selectedFormats}
                        setSelectedFormats={setSelectedFormats}
                        setSearchValue={setSearchValue}
                        tempSearchValue={tempSearchValue}
                        setTempSearchValue={setTempSearchValue}
                    />
                    <FilterSidebar
                        resetFilters={resetFilters}
                        showFeatured={showFeaturedMenu}
                        selectedCategories={selectedCategories}
                        setSelectedCategories={setSelectedCategories}
                        selectedResourceTypes={selectedResourceTypes}
                        setSelectedResourceTypes={setSelectedResourceTypes}
                        selectedGrades={selectedGrades}
                        setSelectedGrades={setSelectedGrades}
                        selectedFormats={selectedFormats}
                        setSelectedFormats={setSelectedFormats}
                        setSearchValue={setSearchValue}
                        tempSearchValue={tempSearchValue}
                        setTempSearchValue={setTempSearchValue}
                    />
                </Col>
                {/* MAIN TEMPLATE CONTENT */}
                <Col xs={12} sm={8} lg={8} xl={9} className="p-4">
                    <SortButtons
                        setSortValue={setSortValue}
                        sortValue={sortValue}
                    />
                    {error && !isApiLoading && (
                        <p>Error fetching Content Library</p>
                    )}
                    {!error && isApiLoading && (
                        <Row className="m-0 p-3 p-lg-4">
                            <Col>
                                <span
                                    className="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                ></span>
                            </Col>
                        </Row>
                    )}
                    {showFeaturedMenu && (
                        <>
                            <Row className="g-3">
                                <Col>
                                    <p className="fw-bold">Featured</p>
                                </Col>
                            </Row>
                            <Row className="g-3">
                                {featured &&
                                    featured.map((listing) => {
                                        if (!listing.bundleattachment_set) {
                                            return (
                                                <TemplateCard
                                                    key={listing.id}
                                                    bundle={
                                                        !!listing.bundleattachment_set
                                                    }
                                                    refresh={refresh}
                                                    template={listing}
                                                />
                                            );
                                        } else {
                                            return (
                                                <BundleCard
                                                    key={listing.id}
                                                    bundle={listing}
                                                    refresh={refresh}
                                                />
                                            );
                                        }
                                    })}
                            </Row>
                        </>
                    )}
                    <Row className="g-3 mt-4">
                        <Col>
                            {!error && !isApiLoading && (
                                <>
                                    <Row className="g-3">
                                        <p className="fw-bold">
                                            {searchValue || !showFeaturedMenu
                                                ? "Results"
                                                : "All"}
                                        </p>
                                        {templates.results.map((template) => (
                                            <TemplateCard
                                                key={template.id}
                                                refresh={refresh}
                                                template={template}
                                            />
                                        ))}
                                        {templates?.results.length === 0 && (
                                            <Col xs={12}>
                                                <p className="fw-bold">
                                                    Unfortunately there was no
                                                    results that matched your
                                                    filters.
                                                </p>
                                                <p className="text-muted fw-light">
                                                    We'd love for you to tell us
                                                    about what you are looking
                                                    for so we can incorporate it
                                                    in our future builds. Let us
                                                    know at{" "}
                                                    <a href="mailto:contact@gomegaminds.com">
                                                        contact@gomegaminds.com
                                                    </a>
                                                </p>
                                                <Button
                                                    variant="purple-dark"
                                                    onClick={() =>
                                                        resetFilters()
                                                    }
                                                >
                                                    Reset filters
                                                </Button>
                                            </Col>
                                        )}
                                    </Row>
                                </>
                            )}
                        </Col>
                    </Row>
                    <Row className="mt-2">
                        <Col>
                            <Paginator
                                items={templates}
                                page={page}
                                setPage={setPage}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    );
};

export default Explore;
