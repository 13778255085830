export const getPicture = (url) => {
    if (process.env.REACT_APP_MM_IS_LOCAL === "True") {
        if (url.startsWith("/")) {
            return "http://localhost:8000" + url;
        } else {
            return url;
        }
    } else {
        return url;
    }

    return "";
};
