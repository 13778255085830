import React from "react";
import { AuthContext } from "./context/AuthContext";
import "./App.css";
import ErrorScreen from "./routes/ErrorScreen";
import { useAuth0 } from "@auth0/auth0-react";
import "react-tooltip/dist/react-tooltip.css";
import OnboardingFlow from "./routes/OnboardingFlow";
import useProfileAPI from "./services/useProfileAPI";
import usePageTracking from "./services/usePageTracking";
import Root from "./routes/Root";
import LoadingPage from "./components/loaders/LoadingPage";

function App() {
    const {
        isLoading,
        isAuthenticated,
        error,
        loginWithRedirect,
        user,
        logout,
    } = useAuth0();


    const [profile, isProfileLoading, isProfileError, refreshProfile, isProfileBanned] =
        useProfileAPI(isAuthenticated, isLoading);

    usePageTracking(profile);

    if (error && !isLoading) {
        console.error(error);
        return (
            <ErrorScreen
                error="There was an error logging you in."
                showLoginButton={true}
                loginWithRedirect={loginWithRedirect}
            />
        );
    }

    if (isProfileBanned) {
        console.error(isProfileError);
        return (
            <ErrorScreen
                error="Your account has been disabled."
            />
        );
    }


    const redirectToHome = () => {
        window.location.replace("/");
    };

    // If for some reason we cant get a profile, most likely the servers are down!
    if (!isProfileLoading && isProfileError) {
        console.error("Got error from inactivity?", isProfileError, error);
        // Determine if inactive or network error
        if (isProfileError.name === "TypeError") {
            return (
                <ErrorScreen
                    errorTitle="Could not connect to MegaMinds"
                    error="This is likely due to scheduled maintainance or an error on our side. The development team is automatically notified regarding downtime."
                />
            );
        } else {
            return (
                <ErrorScreen
                    showLoginButton={true}
                    loginWithRedirect={loginWithRedirect}
                    errorTitle="Session Expired"
                    error="Your session has expired due to inactivity and you need to log in again."
                />
            );
        }
    }

    // If new user
    if (!isProfileLoading && profile?.setup === false) {
        return <OnboardingFlow onComplete={redirectToHome} />;
    }

    if (isProfileLoading) {
        return <LoadingPage />;
    }

    // From this point, the user should be authed on Auth0, finished onboarding and be ready to fetch information from our APIs.

    return (
        <AuthContext.Provider
            value={{
                profile: profile,
                user: user,
                isLoading: isLoading,
                isProfileLoading: isProfileLoading,
                refreshProfile: refreshProfile,
                logout: logout,
            }}
        >
            <Root user={user}></Root>
        </AuthContext.Provider>
    );
}

export default App;
