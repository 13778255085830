import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import ClassRoomCard from "../cards/ClassRoomCard";
import Paginator from "../../../components/Paginator";
import EmptyRoomsPage from "../EmptyRoomsPage";
import { Socket } from "phoenix";


function YourRoomsTab({
    auth,
    restricted,
    restrictedStudentProjects,
    refreshRooms,
    rooms,
    newRoom,
    showInviteModal,
    showAssignModal,
    handleSearch,
    searchValue,
    sortValue,
    setSortValue,
    page,
    setPage,
    refreshSingleRoom,
    isLoadingRooms,
    roomsError,
}) {
    const [socket, setSocket] = useState(null);

    useEffect(() => {
        const socketSettings = {};
        socketSettings.logger = (kind, msg, data) => {
            // console.log(kind, msg, data);
        };

        const socket = new Socket(
            process.env.REACT_APP_PHOENIX_ENDPOINT,
            socketSettings
        );
        socket.onError(async (error) => {
            // console.log("Socket errored", error);
        });

        socket.connect();
        setSocket(socket);
    }, [rooms]);
    
    return (
        <>
        {isLoadingRooms && (
                        <Row className="m-0 p-3 p-lg-4">
                            <Col>
                                <span
                                    className="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                ></span>
                            </Col>
                        </Row>
                    )}

                    {!isLoadingRooms && roomsError && (
                        <Row className="m-0 p-3 p-lg-4">
                            <Col>
                                <p>{roomsError}</p>
                            </Col>
                        </Row>
                    )}
        {searchValue ||
            (!isLoadingRooms &&
                !roomsError &&
                rooms &&
                rooms?.results?.length > 0) ? (
                <Container className="p-0 m-0">
                    <Row className="g-3">
                        {rooms &&
                            rooms?.results?.length >= 0 &&
                            rooms?.results?.map((room) => (
                                <ClassRoomCard
                                    refreshSingleRoom={refreshSingleRoom}
                                    refresh={refreshRooms}
                                    newRoom={newRoom === room.id}
                                    classroom={room}
                                    disabled={
                                        auth.profile?.has_active_subscription &&
                                        rooms?.results?.length > 3
                                    }
                                    profile={auth.profile}
                                    auth={auth}
                                    showInviteModal={showInviteModal}
                                    showAssignModal={showAssignModal}
                                    socket={socket}
                                    key={room.id}
                                    restricted={restricted}
                                    restrictedStudentProjects={
                                        restrictedStudentProjects
                                    }
                                />
                            ))}
                    </Row>
                    <Col sm={12} md={12} lg={12} xxl={12} className="mt-3">
                        {searchValue &&
                            rooms?.length >= 1 &&
                            (!rooms ||
                                (rooms?.results?.length <= 0 && (
                                    <Col xs={3}>
                                        <p>No rooms matched your filters.</p>
                                        <Button
                                            variant="purple-dark"
                                            onClick={() => null}
                                        >
                                            Reset filters
                                        </Button>
                                    </Col>
                                )))}
                        {searchValue && rooms?.results?.length <= 0 && (
                            <Row>
                                <Col>
                                    <p>No rooms matched your search.</p>
                                    <Button
                                        variant="purple-dark"
                                        onClick={() => handleSearch("")}
                                    >
                                        Reset filters
                                    </Button>
                                </Col>
                            </Row>
                        )}
                    </Col>

                    {rooms?.count > 20 && (
                        <Row className="m-0 p-3 p-lg-4">
                            <Paginator
                                items={rooms}
                                page={page}
                                setPage={setPage}
                            />
                        </Row>
                    )}
                </Container>
            ) : (
                <EmptyRoomsPage isLoadingRooms={isLoadingRooms} />
            )}
        </>
    );
}
export default YourRoomsTab;
