import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Col";

export default function SelectType(props) {
    const handleNextStep = (next) => {
        props.setStep(next);
    };

    return (
        <Container className="">
            <Row className="justify-content-center">
                <Col md="auto" className="m-0">
                    <h2 className="pt-5 mb-5 text-center">
                        Chose your account type
                    </h2>
                </Col>
            </Row>
            <Row className="d-flex justify-content-center">
                <Col
                    className="m-3 card-body card p-0 account-card"
                    style={{ maxWidth: "220px" }}
                    onClick={() => handleNextStep("teacher")}
                >
                    <div
                        style={{
                            minHeight: "200px",
                        }}
                        className="text-center bg-purple-dark d-flex justify-content-center align-items-center"
                    >
                        <i
                            style={{ fontSize: "3rem" }}
                            className="bi bi-book text-white"
                        ></i>
                    </div>
                    <h5 className="p-5 fw-bold text-center">Teacher</h5>
                </Col>
                <Col
                    className="m-3 card-body card p-0 account-card"
                    style={{ maxWidth: "220px" }}
                    onClick={() => handleNextStep("professional")}
                >
                    <div
                        style={{
                            minHeight: "200px",
                        }}
                        className="text-center d-flex bg-dark justify-content-center align-items-center"
                    >
                        <i
                            style={{ fontSize: "3rem" }}
                            className="bi bi-briefcase text-white"
                        ></i>
                    </div>
                    <h5 className="p-5 fw-bold text-center">Professional</h5>
                </Col>
                <Col
                    className="m-3 card-body card p-0 account-card"
                    style={{ maxWidth: "220px" }}
                    onClick={() => handleNextStep("personal")}
                >
                    <div
                        style={{
                            backgroundColor: "#578239",
                            minHeight: "200px",
                        }}
                        className="text-center bg-warning d-flex justify-content-center align-items-center"
                    >
                        <i
                            style={{ fontSize: "3rem" }}
                            className="bi bi-house-door text-white"
                        ></i>
                    </div>
                    <h5 className="p-5 fw-bold text-center">Personal</h5>
                </Col>
            </Row>
        </Container>
    );
}
